


















import {
  Component,
  Mixins,
  Prop,
} from 'vue-property-decorator';

import ExLoading from '@/globalComponents/ExLoading/index.vue';
import Tabs from '@/components/Tabs/Tabs.vue';

import BookService from '@/services/Book/BookService';

import Permission from '@/mixins/permission';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

import { PERMISSION_NAME_BY_DEGREE_ID } from '@/constant/PermissionBookByDegreeID';

interface TabsInterface {
  name: string;
  title?: string;
  ID: number;
  order: number;
  status: number;
  active?: boolean;
}

@Component({
  components: {
    ExLoading,
    Tabs,
  },
})
export default class BookTabs extends Mixins(Permission) {
  @Prop() hasBooks!: boolean;

  private isLoadingTabs = true;

  private tabs: TabsInterface[] = [];
  private tabsWithPermission: TabsInterface[] = [];

  private BookService = new BookService();

  async created() {
    await this.getTabs();
    this.filterTabsWithPermission();
    this.activateFirstTab();
    this.emitActiveTabDegree();
  }

  async getTabs() {
    try {
      this.isLoadingTabs = true;

      const tabs = await this.BookService.getDegrees();

      this.tabs = this.setTabs(tabs);
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingTabs = false;
    }
  }

  setTabs(tabs: TabsInterface[]) {
    return tabs.map((tab: TabsInterface) => ({ ...tab, title: tab.name }));
  }

  filterTabsWithPermission() {
    if (!this.tabs.length) return;

    this.tabsWithPermission = this.tabs.filter((tab: TabsInterface) => this.can(PERMISSION_NAME_BY_DEGREE_ID[tab.ID]));
  }

  activateFirstTab() {
    if (!this.tabsWithPermission.length) return;

    this.tabsWithPermission = this.tabsWithPermission.map((tab: TabsInterface, index: number) => ({ ...tab, active: (index === 0) }));
  }

  handleTabClick(indexTabSelected: number) {
    if (this.tabsWithPermission[indexTabSelected].active) return;

    this.tabsWithPermission = this.tabsWithPermission.map((tab: TabsInterface, index: number) => ({ ...tab, active: index === indexTabSelected }));

    const tabActive = this.tabsWithPermission[indexTabSelected];

    if (tabActive.ID) {
      this.setTrackAmplitude(tabActive.name);
      this.emitActiveTabDegree(tabActive.ID);
    }
  }

  emitActiveTabDegree(degreeID?: number) {
    const activeTabDegree = degreeID !== undefined ? degreeID : this.tabsWithPermission.find((tab: TabsInterface) => tab.active)?.ID;

    this.$emit('active-tab-degree', activeTabDegree);
  }

  setTrackAmplitude(nameTab: string) {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'Tabs',
          local: nameTab,
        },
      },
    });
  }
}
